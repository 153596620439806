<template>
  <div class="participants">
    <nav>
    </nav>
    <spread-table :pageSizes="[5, 20, 50, 100]" v-loading="loading" :tableColumn="columns" :tableData="tableData" :smallType="false" :pageSize="pageSize" :currentPage="currentPage" :operation="operation" :total="total" :indexMode="true" indexTitle="acid" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" />
  </div>
</template>

<script>
import SpreadTable from "@/components/SpreadTable";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { getUserByCompetition } from "../apis";
import { deleteSingleUser } from "../apis";
import { participantsData } from "../datas";
import { userInfo } from "../methods";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "participants",
  components: {
    SpreadTable,
  },
  setup() {
    let data = reactive(participantsData);
    onMounted(() => {
      data.currentPage = 1
      data.pageSize = 5
      getUser()
    });

    const getUser = () => {
      data.loading = true;
      const id = JSON.parse(sessionStorage.getItem('mega')).id;
      const reqData = {
        schoolId: userInfo().schoolId,
        competitionId: id,
        pageIndex: data.currentPage,
        pageSize: data.pageSize,
      };
      getUserByCompetition(reqData)
        .then(res => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.tableData = resData.data.sysUserVOPageInfo ? resData.data.sysUserVOPageInfo.list : []
            data.total = resData.data.sysUserVOPageInfo ? resData.data.sysUserVOPageInfo.total : 0
          } else {

          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const confirmUser = () => {

    }

    const sizeChange = size => {
      data.pageSize = size
      data.currentPage = 1
      getUser()
    }

    const currentChange = current => {
      data.currentPage = current
      getUser()
    }
    let clickButton = (val) => {
      const id = JSON.parse(sessionStorage.getItem('mega')).id;

      const reqData = {
        competitionId: id,
        userId: val.item.id,
        userName: userInfo().userName
      }
      deleteSingleUser(reqData).then(res => {
        if (res.data.code === '200') {
          ElMessage.success(res.data.msg)
          getUser();
        } else {
          ElMessage.error(res.data.msg)
        }

      })
    }

    return {
      ...toRefs(data),
      getUser,
      confirmUser,
      clickButton,
      sizeChange,
      currentChange,
    };
  },
});
</script>

<style scoped lang="less">
.participants {
}
</style>